import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/EN/LayoutEN'
import './PersonDetailPage.css'
import { Link } from '@reach/router'
import Img from 'gatsby-image'
import Image from '../components/Default/Image'

import Fade from 'react-reveal'
const PersonDetailPageTemplate = props => {
  return (
    <div>
      <div className="PersonDetailPage">
        <Image
          className="top-yellow"
          src={props.rightArrow.fluid.src}
          alt="top-yellow"
        />
        <Image
          className="top-blue"
          src={props.leftArrow.fluid.src}
          alt="top-blue"
        />
        <div className="container" style={{ marginTop: '16rem' }}>
          <h1>{props.title}</h1>
          {!!props.image && !!props.image.childImageSharp ? (
            <Img fixed={props.image.childImageSharp.fixed} alt={props.title} />
          ) : (
            <img src={props.image.src} alt={props.title} />
          )}

          <Fade bottom>
            <div dangerouslySetInnerHTML={{ __html: props.body }} />
          </Fade>
        </div>
        <div className="Contanct-area">
          <div className="Content container">
            <h3>Contact us</h3>
            <a className="phone" href="tel:+420273139330">
              + 420 273 139 330
            </a>
            <div className="row align-items-start">
              <div className="col-lg-4 col-md-6 left">
                <a
                  href="/en/about-us/our-team/"
                  className="button button-secondary"
                >
                  Our team
                </a>
              </div>
              <div className="col-lg-4 col-md-6">
                <Link to="/en/contact-us#formAnchor" className="button">
                  Contact form
                </Link>
              </div>
              <div className="col-lg-4 col-md-12 right">
                <Link to="/en/contact-us#nasePobocky" className="button">
                  Our branches
                </Link>
              </div>
            </div>
          </div>
          <Image
            className="bottom-yellow"
            src={props.rightArrow.fluid.src}
            alt="bottom-yellow"
          />
          <Image
            className="bottom-blue"
            src={props.leftArrow.fluid.src}
            alt="bottom-blue"
          />
        </div>
      </div>
    </div>
  )
}

const PersonDetailPage = ({
  data: { page, rightArrow, leftArrow },
  pageContext,
}) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext
  return (
    <Layout
      meta={page.frontmatter.meta || false}
      title={page.frontmatter.title || false}
      metaCrumb={crumbs}
    >
      <PersonDetailPageTemplate
        {...page.frontmatter}
        body={page.html}
        rightArrow={rightArrow.childImageSharp}
        leftArrow={leftArrow.childImageSharp}
        crumbs={crumbs}
      />
    </Layout>
  )
}

export default PersonDetailPage

export const pageQuery = graphql`
  query PersonDetailPageNoBreadEN($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        image {
          childImageSharp {
            fixed(width: 200, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    rightArrow: file(relativePath: { eq: "right-arrow.png" }) {
      childImageSharp {
        fluid {
          src
        }
      }
    }
    leftArrow: file(relativePath: { eq: "left-arrow.png" }) {
      childImageSharp {
        fluid {
          src
        }
      }
    }
  }
`
